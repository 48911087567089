<template>
  <mobile-screen :header="true" screen-class="change-password-screen icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_profile-help-online-sync-outlook'
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "profile",
              "profile",
              "authorize-synchronization-with-outlook"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="userOutlookSync"
                  type="checkbox"
                  v-model="isSynced"
                />
                <label for="userOutlookSync"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("profile", "profile", "is-active") }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section">
      <li
        class="clebex-item-section-item"
        v-if="outlookSyncUrl && outlookSyncUrl.data"
      >
        <a :href="outlookSyncUrl.data.url" class="clebex-item-content-wrapper">
          <span class="label">
            {{ displayLabelName("profile", "profile", "link-with-outlook") }}
          </span>

          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </span>
          </span>
        </a>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { getSubdomain } from "@/services/http-service";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SyncWithOutlook",
  mixins: [helpOnlineMixin],
  data() {
    return {
      submitting: false,
      isSynced: false,
      helpSlug: "profile-sync"
    };
  },
  created() {
    if (this.hasSubdomain) {
      this.getCompanyUserOutlookSyncUrl();
    }
  },
  computed: {
    ...mapState("user", ["userData", "company", "outlookSyncUrl"]),
    hasOutlookSync() {
      const { userData } = this;
      if (userData && userData.data) {
        return Boolean(userData.data.is_authorized_with_outlook);
      }
      return null;
    },
    hasSubdomain() {
      return getSubdomain();
    }
  },
  watch: {
    hasOutlookSync: {
      handler(val) {
        this.isSynced = Boolean(val);
      },
      immediate: true
    },
    isSynced(val) {
      this.$store.dispatch("user/setOutlookSynced", val, { root: true });
      this.$router.push({ name: this.backLinkName });
    }
  },
  methods: {
    ...mapActions("user", ["getCompanyUserOutlookSyncUrl"])
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String
    }
  }
};
</script>
